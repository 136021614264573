* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

*/:root {
    background-color: #215497;
}

body {
    background-color: '#F4F6F8';
}


.pagina {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    min-height: 100%;
    background-color: #E58934;
}

.dashboard {
    background-color: red;
    display: flex;
}

.modal-75 {
    min-width: 75%;
}

.titulo-dash {
    margin-top: 5px;
    margin-bottom: -10px;
    color: white;
    cursor: pointer;
}

.titulo-dash p {
    color: white;
}

.centraliza {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icone-nav {
    margin-right: 10px;
}

.barra-dash {
    color: white;
    background-color: #E58934;
}

.navbar-light .navbar-nav .nav-link {
    color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: white;
    text-decoration: underline;
}

.navbar-light .navbar-nav .nav-link.active {
    color: white;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: white;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: white;
}

.navbar-toggler {
    background-color: white;
}

.navbar-collapse {
    flex-grow: 0;
}

.item-branco {
    color: white;
}

.box-login {
    width: 400px;
    height: 500px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
}

.box-login-titulo {
    width: 100%;
    height: 60px;
    margin-top: 15px;
    margin-bottom: 40%;
}

.box-login-titulo h1,
h2 {
    color: #215497;
    text-align: center;
}

.box-login-inputs {
    width: 80%;
    height: 55%;
}

.box-botao-acessar {
    display: flex;
    justify-content: center;
}

.botao-acessar {
    background-color: #E58934;
    border: none;
}

.botao-acessar:active {
    background-color: #E58934;
    border: none;
}

.botao-acessar::before {
    background-color: #E58934;
    border: none;
}

.botao-acessar:hover {
    background-color: #E58934;
    border: solid 2px #E58934;
}

@media only screen and (max-width: 768px) {
    .box-login {
        width: 80%;
    }

    .dash-sidebar {
        background-color: white;
        width: 40%;
    }

    .dash-conteudo {
        background-color: gainsboro;
        width: 80%;
        display: flex;
    }
}

@media only screen and (max-width: 350px) {
    .box-login {
        width: 80%;
    }

    .box-login-titulo h1 {
        font-size: 20px;
    }

    .box-login-titulo h2 {
        font-size: 16px;
    }
}